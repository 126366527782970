import { ExpertiseSectionMock } from '~/types/mock'

const MvpBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_66',
    title: 'Launch quickly',
    description:
      'You don’t have to spend much time and build a fully-fledged product. Only the core functionality is created.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Minimize costs',
    description:
      'Less functionality means less development effort and a smaller budget. It’s the best way to validate your idea for an affordable price.',
  },
  {
    icon: 'spriteIcon_17',
    title: 'Test idea fast',
    description:
      'Get feedback from early adopters, and decide whether to expand your product further or abandon the idea.',
  },
]

export default MvpBenefits
