import { BaseMock } from '~/types/mock'

const MvpWhyChoose: BaseMock[] = [
  {
    title: 'Save costs and launch quickly',
    description:
      'Our vast expertise and efficient MVP development process allow us to deliver your product on time and on budget.',
  },
  {
    title: 'MVP development expertise',
    description:
      'Having built numerous MVPs for multiple industries, our team offers the best solution for your business challenges.',
  },
  {
    title: 'Enthusiastic team that cares',
    description:
      'Our result-oriented team loves solving challenges with technology and innovation and treats each project as a game-changer.',
  },
  {
    title: 'The right solution for your needs',
    description:
      'Client success is the priority of our top MVP development company. We carefully align your business requirements with software functionality to ensure the best value.',
  },
  {
    title: 'Full control over development',
    description:
      'Based on the agile development approach, our work process is flexible and fully transparent, with daily and weekly progress updates.',
  },
  {
    title: 'Support after launch',
    description:
      'Our work doesn’t stop when your MVP website goes live. We are here to support or help you further develop your product.',
  },
]

export default MvpWhyChoose
