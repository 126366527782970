import { FAQ } from '~/types/data-array'

const MvpFAQ: FAQ[] = [
  {
    question: 'What is a minimum viable product?',
    answer:
      'A minimum viable product is a solution with enough functionality to engage early customers. It helps validate your business idea at early development stages.',
  },
  {
    question: 'Why does the product need an MVP?',
    answer:
      'We recommend building an MVP as it delivers immediate value, reduces development expenses, and collects user feedback that can be applied to enhance future iterations.',
  },
  {
    question: 'Why should a startup use an MVP development?',
    answer:
      'An MVP for startups helps to lower risks and save your budget, time, and effort. By the way, with a minimum viable product, you get better chances to attract investors.',
  },
  {
    question: 'How much does it cost to develop an MVP?',
    answer:
      'The average cost of the MVP is about $30,000-$70,000. The final cost of an MVP depends on the project’s complexity, how expert the team is, the rates of software developers, features you want to add, and more.',
  },
]

export default MvpFAQ
