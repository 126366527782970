import { ExpertiseSectionMock } from '~/types/mock'

const MvpApproach: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_92',
    title: 'Weekly sprints',
    description:
      'Dividing the process into sprints helps us to be aware of outcomes at each development stage.',
  },
  {
    icon: 'spriteIcon_79',
    title: 'Daily standups',
    description:
      'In everyday meetings, we share results and set the next efforts in sprint delivery.',
  },
  {
    icon: 'spriteIcon_72',
    title: 'Strong communications',
    description:
      'Broad engagement with clients helps us to build great solutions that meet clients’ needs.',
  },
  {
    icon: 'spriteIcon_93',
    title: 'Constant retrospectives',
    description:
      'At the end of each sprint, we analyze gained results and set actions for future improvements.',
  },
  {
    icon: 'spriteIcon_94',
    title: 'Skilled project manager',
    description:
      'In-house PM helps control the development process so a client gets a successful product.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Transparent development process',
    description:
      'With our best practices and tools, we can avoid tech risks while writing the code and make it resistant to faults.',
  },
]

export default MvpApproach
