import { ExpertiseSectionMock } from '~/types/mock'

const MvpBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'MVP development for startups',
    description:
      'Concerning minimum viable product development services for startups, our experts put maximum effort into market analysis and planning. The aim of the MVP development service is to check your idea and manage it with the relevant market needs. We’ll create the right MVP for you, so, you will get all chances to attract interested investors and grow your business.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'MVP for small businesses',
    description:
      'You may think that growing a small business can be a challenge. However, if you begin your business with MVP development, it will facilitate moving to the next level. At Codica, we always pick the optimal development course and proven tech stack to provide a great customer experience with less handwork, saving your time and budget.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'MVP development for mid-scale business',
    description:
      'The minimum viable product development services for any business need quick delivery for a cost-effective investment. Our agile approach helps us manage the development process carefully, reducing the risks of defects. We provide the MVP and full test coverage before the product launch to enhance purchasers’ experience and save you from any resource losses.',
  },
  {
    icon: 'sprite3DIconSmall_19',
    title: 'MVP development for enterprises',
    description:
      'We create MVPs for enterprises, focusing on the particular corporate IT and its characteristics. Our experienced specialists can transform MVPs into complex and fully-functional products that will be relevant on the market. Moreover, we create usable UI and UX designs to attract people who will try your MVP and help keep them with you.',
  },
]

export default MvpBusinesses
