import { HomeSpecification } from '~/types/mock'

const MvpExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'To save our clients time, money, and intellectual resources, we recommend starting marketplace development with market testing. For this, we develop quality marketplace MVPs.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'MVP is a “quick track” of eCommerce deployments. We focus on developing key features for your business to operate. This helps us to enhance and add new features in phases.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'With an MVP, you can test the solution concept and drive a primary audience. Then a travel MVP can be further transformed into a complex application for broader audiences.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'If you plan an automotive business, we will help you with our MVP development services. So you will be able to test your idea and improve the solution if needed.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'Users pick an insurance business firm that can look out for their best interest. With the MVP development service, we can find out more about your users and satisfy their needs.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'The best way to start your rental business is to implement the major features to the MVP, test them on your target audience, and then analyze the result.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Building a rental custom solution with Codica will be the best choice regarding efficiency, customization, and customer experience.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'The quality MVP development services we provide for the media business can help you win the hearts of your users. Also, you will evaluate strategies for the product’s further growth.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'We create reliable and efficient MVPs for the healthcare domain. So you can ensure your solution benefits people, brings business value, and has growth potential.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default MvpExpertise
