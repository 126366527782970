// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "z_dR";
export var businessesSectionMvp = "z_dP";
export var clientsMvpSection = "z_d1";
export var devApproachSectionMvp = "z_dS";
export var domainExpertiseMvpSection = "z_dN";
export var launchProductSectionMvp = "z_dT";
export var launchProductSectionMvp__testimonial = "z_dV";
export var ourAchievementsMvp = "z_d0";
export var servicesCardBorderIconMvpSection = "z_dM";
export var servicesDevExpertiseMvp = "z_dW";
export var servicesProcessMvpSection = "z_dQ";
export var successStoriesMvpSection = "z_dY";
export var technologiesSectionMvp = "z_dX";
export var whyChooseMvpSection = "z_dZ";