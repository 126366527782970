import { ServicesProcessMock } from '~/types/mock'

const MvpProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: 'Product discovery and analysis',
    descr:
      'This is the first stage of developing an MVP. It helps our expert team understand your idea and the problem you want to solve.',
  },
  {
    num: '02',
    title: 'Product UI/UX design',
    descr:
      'At this stage, we create your MVP product interfaces. Designing prototypes helps us implement the user journey and make an intuitive app.',
  },
  {
    num: '03',
    title: 'Minimum viable product development',
    descr:
      'This is where programming takes place. We pick the best tech stack and build the MVP based on the latest industry standards.',
  },
  {
    num: '04',
    title: 'Quality assurance and optimization',
    descr:
      'Thorough testing of an MVP website and optimizing the code allows us to ensure stable work and satisfaction for your early users.',
  },
  {
    num: '05',
    title: 'Deployment and support',
    descr:
      'We monitor the software once it’s deployed and solve any issues that may arise. Our team can take care of your app and support it on a regular basis.',
  },
]

export default MvpProcess
