import { DevExpertiseCompanyMock } from '~/types/mock'

const MvpDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '50+',
    text: 'projects developed',
  },
  {
    title: '$56+',
    text: 'mln received by our clients',
  },
  {
    title: '60+',
    text: 'experts on board',
  },
  {
    title: '50+',
    text: 'happy clients',
  },
]

export default MvpDevExpertise
