import { ExpertiseSectionMock } from '~/types/mock'

const MvpServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'MVP consulting',
    description:
      'With Codica, you get mature professionals behind you that provide quality consulting services on MVP development to help your business meet all of your needs. We apply best practices and methods to improve and lead your MVP solution, even from surviving to thriving.',
  },
  {
    icon: 'sprite3DIcon_18',
    title: 'Product discovery',
    link: '/services/product-discovery/',
    description:
      'Product discovery is an essential MVP development service. We conduct market research and test different hypotheses. This enables us to build the MVP that brings the most value to your business and your customers.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'MVP product design (UI/UX)',
    description:
      'Quality minimum viable product design with Codica builds trust. To stay in game, you should have a product with effective UX and UI. With us, you get a usable and appealing digital product that can be transformed into a profitable venture.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'MVP web development',
    link: '/services/mvp-web-development/',
    description:
      'The proven and efficient technologies are the core of the MVP product development with Codica. Our expereicned specialists help you build a secure and high-quality product within a short time. So, your product will be released as quickly as possible.',
  },
  {
    icon: 'sprite3DIcon_15',
    link: '/services/mvp-app-development/',
    title: 'MVP app development',
    description:
      'At Codica, our mobile app development specialists assist our clients in implementing their bold business ideas. We provide clients with top-quality MVP mobile solutions with core functions that satisfy users and strengthen customers’ awareness of your brand.',
  },
]

export default MvpServices
